// import EventBus from "../../Lib/EventBus";

import {mapGetters} from "vuex";
import {Contries} from "../../Lib/contries";
import {TIMEZONES} from "../../Lib/timezones";
import moment from "moment-jalaali";
import Axios from "axios";
import FileDownload from "js-file-download";

// import {parsePhoneNumberFromString} from "libphonenumber-js/custom";
/*eslint-disable*/


/**
 * <h1>Global Mixins</h1>  <p>This function shared to all component for use as mixin</p>
 * @vue-data {array} [imageTypes] - hold supported image extension
 * @vue-data {array} [overlays] - hold overlay routes
 * @vue-data {string} [payBtnId] - hold pay btn id
 * @vue-computed {Object} platFormInfoLS - share platform info with all component from vuex and localStorage
 * @vue-computed {Boolean} loadingStatus - share loading status with all component from vuex
 * @vue-computed {Boolean} isOverlay - check route with overlay array
 * @vue-computed {Rule} requiredRules - required rule for input
 * @vue-computed {Rule} passwordRules - password rules for password input
 * @vue-computed {Rule} passwordConfirmRules - password confirm rules for password confirm input
 * @vue-computed {Rule} emailRules - email rules for email input
 * @vue-computed {Object} contires - contries list form country json file
 * @vue-computed {Object} timezones - timezones list form country json file
 * @vue-computed {Boolean} isLoggedin - return auth status user from vuex
 *
 *
 */
export default {
    methods: {
        attachTelegramAccount() {
            let telegram_token = localStorage.getItem('telegram_auth_token')

            if (telegram_token) {
                this.requesting('auth', 'submit_telegram_token', {}, {
                    telegram_auth_token: telegram_token
                }).then(() => {
                    localStorage.removeItem('telegram_auth_token')
                })
            }
        },
        resolveCurrency(input) {
            let result = '';
            switch (input) {
                case 'rial':
                    result = 'currency.longRial';
                    break;
                case 'dollar':
                    result = 'currency.dollarLong';
                    break;
                case 'cad':
                    result = 'currency.dollarCanadaLong';
                    break;
                case 'aud':
                    result = 'currency.dollarAustraliaLong';
                    break;
                case 'euro':
                    result = 'currency.EuroLong';
                    break;
                case 'gbp':
                    result = 'currency.poundLong';
                    break;
                case 'try':
                    result = 'currency.try';
                    break;
            }
            return result;
        },

        AxiosWithDownload(path, name, type) {
            var ajaxUrl = process.env.VUE_APP_BASE_URL + path;
            try {
                return new Promise((resolve, reject) => {
                    Axios({
                        method: 'GET',
                        url: ajaxUrl,
                        withCredentials: true,
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                        responseType: 'blob',

                    }).then(resp => {
                        FileDownload(resp.data, name + "." + type);
                        resolve();
                    }).catch((err) => {
                        reject(err);
                    }).finally(() => {
                    });
                });
            } catch (err) {
                // EventBus.$emit('notify', 'red', undefined, 'خطایی رخ داده است');
                // store.commit('setLoading', false);
            }
        },

        jalaliToGregorian(date, format = 'Y-MM-DD') {
            return moment(date, 'jYYYY-jM-jDD').format(format)
        },

        /**
         *
         * This is Function for login or register with social media and email account. with this method after call, redirect to appropriate url for login
         *
         * @param {string} target way of login or register
         *
         * */
        loginBy(target) {
            switch (target) {
                case 'google':
                    window.location.href = 'https://api.simiaroom.com/api/v1/auth/social/google';
            }
        },

        /**
         *
         * This is Function for find api and send request with provided header and payload.<br/>
         * This function use vuex dispatch <i>generalRequest</i>
         *
         * @param {string} type=null type of api want to call: auth , test , .....
         * @param {string} name=null name of api that declare in request files
         * @param {string} paramsQuery={} parameters for url with key : value structure,key replace with value of key
         * @param {string} body={} body of request
         * @param {string} header={} header of request
         *
         * @return {Promise}
         * */
        requesting(type = null, name = null, paramsQuery = {}, body = {}, header = {}) {
            return this.$store.dispatch('generalRequest', {
                type: type,
                name: name,
                paramsQuery: paramsQuery,
                body: body,
                headers: header,
            })
        },


        /**
         *
         * This function is for render currency in appropriate position
         *
         * @param {number} val value that want to add currency code
         * @param {string} currency
         * @param {string} type show long or short term of currency
         * @return {String}
         * */
        withCurrency(val, currency, type = null) {
            switch (currency) {
                case "dollar":
                    return this.$vuetify.rtl ? (` ${Number(val).toFixed(2)} ` + this.$t('currency.dollar')) : this.$t('currency.dollar') + `${Number(val).toFixed(2)} `;
                case "rial":
                    var x = Number(val).toFixed(0);
                    if (type == 'long' && this.$vuetify.rtl) {
                        return Number(x).toLocaleString() + ' ' + this.$t('currency.longRial') + ' ';
                    } else {
                        return Number(x).toLocaleString() + this.$t('currency.rial');
                    }
                case "euro":
                    return this.$vuetify.rtl ? Number(val).toFixed(2) + " " + this.$t('currency.Euro') : '€' + Number(val).toFixed(2)
            }
        },
        /**
         *
         * This function is for find element with id <i>routerArea</i> and scroll it to top
         * */
        toTop() {
            setTimeout(() => {
                this.$nextTick().then(() => {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0;
                })
            }, 500)
            // document.body.scrollTo({top: 0})
        },

        /**
         *
         * This function is for specify that this is a photo
         *
         * @return {Boolean}
         * */
        isImage(type) {
            return this.imageTypes.includes(type.toLowerCase()) || type.startsWith('image');
        },

        /**
         *
         * This function is for Logout user.this function dispatch logout action in vuex that have functionality to logout user
         *
         * */
        logout() {
            this.$store.dispatch('logout')
            this.$emitEvent('notify', {title: 'خارج شدید', color: 'green'})
        },

        /**
         *
         * This function is for get key of  platform  info from localStorage
         *
         * @param {key} key key of platforminfo object
         * */
        getPlatformInfo(key) {
            if (this.platFormInfoLS) {
                return this.platFormInfoLS[key] || null
            } else {
                return false
            }
        },


        /**
         *
         * This function is for get title of platform info from localStorage
         *
         * */
        getPlatformTitle() {
            if (this.platFormInfoLS) {
                return this.$vuetify.rtl ? this.platFormInfoLS.slug : this.platFormInfoLS.name
            }
        },

        /**
         *
         * This function is for get domain of platform info from localStorage and clear http protocols
         *
         * */
        getDomainPlatform() {
            let domain = this.getPlatformInfo('domain')
            if (domain) {
                return domain.replace('https://', '')
            }
            return null

        },

        /**
         *
         * This function is for check filed should be fill in user profile and if not ,
         *
         * <b>redirect user to <i>user-onboarding</i> page to complete profile </b>
         *
         * */
        checkRequiredFiled() {
            let requiredFields = ['firstname', 'lastname', 'cel', 'tz']
            requiredFields.forEach((item) => {
                if (!this.profile[item]) {
                    this.$router.push({name: 'userOnboarding'})
                }
            })
        },

        /**
         *
         * This function get factor state and return appropriate persian/english state
         *
         * @param {Object} factor factor info and must have <b>state</b> key

         * @return {String}
         *
         * */
        factorState(factor) {
            switch (factor.state) {
                case 'paid':
                    return this.$t('factors.factorState.paid')
                case 'submit':
                    if (factor.canBePaid) {
                        return this.$t('factors.factorState.canPay')
                    } else {
                        return this.$t('factors.factorState.submit')
                    }
                case "partially_paid":
                    return this.$t('factors.factorState.partially_paid')
                default :
                    return state
            }
        },

        /**
         *
         * This function get payment type  and return appropriate persian/english wallet type
         *
         *  @param {Object} payment payment info and must have <b>type</b> key
         * @return {String}
         *
         * */
        factorPaymentType(payment) {
            switch (payment.type) {
                case "wallet_debt_settlement":
                    return this.$t('factors.paymentType.wallet_debt_settlement')
                case "online":
                    return this.$t('factors.paymentType.online')
                case "offline":
                    return this.$t('factors.paymentType.offline')
                case "wallet_payment":
                    return this.$t('factors.paymentType.wallet_payment')
                default :
                    return payment.type
            }
        },

        /**
         *
         * This function get payment state and return appropriate persian/english state
         *
         * @param {Object} payment payment info and must have <b>state</b> key

         * @return {String}
         *
         * */
        factorPaymentState(payment) {
            switch (payment.state) {
                case "awaiting":
                    return this.$t('factors.paymentState.awaiting')
                case "pending":
                    return this.$t('factors.paymentState.pending')
                case "paid":
                    return this.$t('factors.paymentState.paid')
                case "wallet_pending":
                    return ""
                case "failed":
                    return this.$t('factors.paymentState.failed')
                case "debit_checkout":
                    return this.$t('factors.paymentState.debit_checkout')
                case "rejected":
                    return this.$t('factors.paymentState.rejected')
                default :
                    return payment.state
            }
        },

    },
    data() {
        return {
            imageTypes: [
                'png', 'jpg', 'jpeg', 'gif'
            ],
            overlays: [
                'wallet',
                'messenger',
                'chat',
                'userOnboarding'
            ],
            payBtnId: null,
        }
    },

    /*
    * */
    computed: {
        ...mapGetters({
            platFormInfoLS: 'getPlatform',
            loadingStatus: 'getLoading',
        }),
        isOverlay() {
            return false
            return this.overlays.findIndex(item => item == this.$route.name) != -1
        },
        requiredRules() {
            return [v => !!v || this.$t('errors.fillIsRequired')];
        },
        contires() {
            return Contries
        },
        timezones() {
            return TIMEZONES
        },
        passwordRules() {
            return [
                v => (typeof v != 'null' && v.length >= 8) || this.$t('errors.passwordLengthError'),
                v => !!v || this.$t('errors.fillIsRequired'),
            ];
        },
        emailRules() {
            return [
                v => !!v || this.$t('errors.fillIsRequired'),
                v => /.+@.+\..{2}/.test(v) || this.$t('errors.emailErrValid'),
            ];
        },

        passwordConfirmRules() {
            return [
                v => !!v || this.$t('Notify.signupComponent.rePasswordRequired'),
                v => (v === this.password) || this.$t('Notify.signupComponent.rePasswordCheck')
            ];
        },

        isLoggedin() {
            return this.$store.getters.userIsLoggedIn;
        },

    },

}