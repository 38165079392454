import auth from './list/authRequests'
import session from './list/sessionsRequests'
import user from './list/userRequests'
import wallet from './list/walletRequests'
import factor from './list/factorRequests'
import packages from './list/packageRequest'
import cart from './list/cart'
import messenger from './list/messengerRequests'
import exercise from './list/exerciseRequests'
import match_making from "./list/match_making";
import test from "./list/testRequests";
import payment from './list/PaymentRequests'
import offlinePayment from "./list/offlinepaymentRequest";

export default {
    auth,
    session,
    user,
    wallet,
    factor,
    packages,
    cart,
    messenger,
    exercise,
    match_making,
    test,
    payment,
    offlinePayment
}