/*eslint-disable*/
import emitter from "./EventEmitter";

export default {
    install(Vue, options) {
        let services = new emitter();
        Vue.prototype.$onEvent = function (name, handler) {
            services.OnEvent(name, handler)
        }
        Vue.prototype.$offEvent = function (name){
            services.OffEvent(name)
        }
        Vue.prototype.$emitEvent = function (name, params) {
            services.EmitEvent(name, params)
        }
    },
};