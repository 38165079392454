<template>
  <div>

  </div>
</template>

<script>
export default {
  mounted() {
    alert('fa')
  }
}
</script>

<style>
* {
  font-family: en-light !important;
}
</style>