export default [
    {
        name: "SendSurveyAnswer",
        path: 'user/onboarding-survey',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "insertCycle",
        path: 'user/cycles',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },
    {
        name: "request-test-analysis",
        path: 'user/request-test-analysis',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {},
        commits: []
    },

]
