export default [
    {
        name: "getOfflinePaymentMethod",
        path: 'user/paymentmethods/getmethods',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "changeUserCurrency",
        path: 'cart/changecurrency',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "saveOpenCartOfflineOrder",
        path: 'user/saveofflineorder',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "saveFactorOfflineOrder",
        path: 'user/factors/:factorId/addofflinepayment',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]