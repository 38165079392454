<template>
  <div>
    <v-fade-transition>
      <div class="loaderArea" v-if="loader">
        <div class="backLoader"></div>
        <div class="loaderSpinner mt-8">
          <lottie-animation
              ref="anim"
              :animationData="require('../../assets/animation/loader.json')"
              :loop="true"
              :autoPlay="true"
          />
          <!--        <v-progress-linear color="primary" indeterminate/>-->
        </div>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
/*eslint-disable*/


import LottieAnimation from 'lottie-web-vue'


export default {
  components: {
    LottieAnimation
  },
  props: {
    loader: Boolean,
  },
  data() {
    return {
      // defaultOptions: {animationData: animationData},
      animationSpeed: 1
    }
  },
  mounted() {
    // console.log(this.animationData, '2112121')
  }
}
</script>

<style scoped>

@media (min-width: 200px ) and (max-width: 700px) {
  .loaderSpinner {
    position: absolute;
    top: 48% !important;
    background: white;
    width: 90% !important;
    padding: 15px;
    border-radius: 12px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
}

.loaderSpinner {
  position: absolute;
  top: 40%;
  background: white;
  width: 300px;
  padding: 15px;
  border-radius: 12px;
  left: 50%;
  transform: translate(-50%, -50%);
  /*box-shadow: 0 4px 12px -5px rgba(0, 0, 0, 0.3);*/
}

.loaderArea {
  position: absolute !important;
  top: 0px;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
</style>