export default [
    {
        name: "getWalletUser",
        path: 'user/sessions/:id/factor',
        method: "download",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getPayableFactor",
        path: 'user/getlastpayablefactor',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getUserFactors",
        path: 'user/factors?page=:page',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getFactor",
        path: 'user/factors/:factorID',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "payFactor",
        path: 'payorder/:factorID',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "addWalletRecord",
        path: 'user/factors/:factorID/addwalletpaymentrecord',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]