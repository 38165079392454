export default [
    {
        name: "getExercise",
        path: 'user/exercise',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]