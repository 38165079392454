export default [
    {
        name: "getAllPackages",
        path: 'package/search?page=:page&perPage=8',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]