const EventEmitter = require('events');

//
class emitter extends EventEmitter {
    OnEvent(name, handler) {
        this.on(name, handler)
    }

    EmitEvent(name, params) {
        this.emit(name, params)
    }

    OffEvent(name) {
        this.off(name)
    }
}

export default emitter