<template>
  <v-app>
    <v-dialog width="800px" persistent v-model="consent_modal" scrollable>
      <v-card>
        <v-card-title class="justify-center font-weight-bold">
          رضایت آگاهانه
        </v-card-title>
        <v-card-text>
          <p class="font-weight-bold">برای شروع روان درمانی در سیمیاروم:</p>

          <div class="section">
            <p> متشکریم که به سیمیاروم اعتماد کردید. خواهشمندیم پیش از شروع مسیر روان‌درمانی، موارد زیر را با
              دقت مطالعه کنید. اين موارد در جهت ارتقا كيفيت خدمات و شفافیت مسیر شما در نظر گرفته شده‌اند.</p>
          </div>

          <div class="section">
            <p class="font-weight-bold">آشنایی با اصول و حقوق متقابل:</p>
            <p>برای شروع جلسات، لازم است مراجعه‌کنندگان و روان‌درمانگران اصول اخلاقی و حقوق متقابل خود را در جلسه
              روان‌درمانی بدانند. می‌توانید از طریق این <a href="https://simiaroom.com/guideline/">لینک</a> هم به آن
              دسترسی داشته باشید.</p>
          </div>

          <div class="section">
            <p class="font-weight-bold">مسیر درمانی:</p>
            <div>
              مسیر درمانی مجموعه‌ای از جلسات روان­درمانی، ویزیت روانپزشکی و یا آزمون روانشناختی است. مسیر درمانی شما با
              قالب متناسب با مساله و منحصر به شما پیشنهاد می‌گردد که شامل یک یا چند موارد فوق الذکر باشد. در این مسیر
              فارغ از مساله و علت مراجعه شما؛
              <ul class="pr-4">
                <li>
                  <b>
                    یک پشتیبان
                  </b>
                  از تیم Customer Care برای هماهنگی جلسات و خرید،
                </li>
                <li>
                  <b>
                    یک روانشناس
                  </b>
                  از تیم Quality Care برای ارجاع و پیگیری کیفیت درمان
                </li>
                <li>
                  <b>
                    یک یا چند روانشناس/روانپزشک
                  </b>
                  از متخصصان سیمیاروم برای روان‌درمانی در کنار شما خواهد بود.
                </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <p class="font-weight-bold">هماهنگی جلسات:</p>
            <p>
              هماهنگی زمان جلسات شما توسط پشتیبان شما در سیمیاروم انجام می شود. جلسات به ويژه در اوايل درمان، نياز است
              به صورت هفتگي و منظم تشكيل شوند؛ چرا كه رعايت اين مساله، تاثير جدي بر اثربخشی درمان دارد1. بدین جهت
              سیمیاروم هنگام شروع مسیر به شما زمان ثابتی از زمان‌های روان‌درمانگر را اختصاص خواهد داد. این زمان اختصاص
              یافته تا پایان مسیر درمان به شما تعلق خواهد داشت و در صورتی که دو هفته مداوم جلسات کنسل ویا برگزار نشود،
              آن زمان در هفته‌ی سوم برای شما محفوظ باقی نمی­ماند. در صورت تبدیل جلسات شما از جلسات هفتگی به چند هفته
              یکبار نیز، زمان جدید ثابتی برای شما در نظر گرفته می شود.
            </p>
          </div>

          <div class="section">
            <p class="font-weight-bold">
              بسترهای ارتباطی سیمیاروم با شما:
            </p>
            <div>
              <ul class="pr-4 ">
                <li>
                  ارسال پیام‌های اتوماتیک و اطلاع رسانی‌ها از طریق ایمیل
                  <a href="mailto:no-reply@simiaroom.com">
                    (no-reply@simiaroom.com)
                  </a>
                </li>
                <li>
                  پیام‌های پشتیبانی در واتس‌اپ یا تلگرام
                </li>
                <li>
                  برگزاری جلسات با روان‌درمانگر در نرم‌افزار اسکایپ (Skype)
                  <u>
                    به صورت تماس تصویری
                  </u>
                </li>
              </ul>
              <br>
              <p class="font-weight-bold">
                نکات مهم درباره جلسات :
              </p>
              <ul class="pr-4">
                <li>
                  خارج از زمان جلسه هماهنگ شده، روان­درمانگر ملزم به پاسخگویی نخواهد بود.
                </li>
                <li>
                  جلسات درمانی حتی به صورت مجازی، رسمی محسوب می‌شوند، بنابراين نياز است قبل از شروع جلسه، شرايط مناسب را
                  فراهم کرده و از وضعیت صدا، تصوير و اینترنت، اطمینان حاصل کنید.
                </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <p class="font-weight-bold">زمان جلسات:</p>
            <div>
              <ul class="pr-4">
                <li>
                  جلسات روان‌درمانی سیمیاروم به صورت استاندارد 45 دقیقه است. چنانچه جلسات بیش از 45 دقیقه زمان نیاز
                  داشته باشد، معادل دقایق اضافه شده، نیاز به پرداخت مازاد خواهد بود.
                </li>
                <li>
                  در صورت تاخير از سوي مراجع، به وقت جلسه اضافه نخواهد شد اما چنانچه روان‌درمانگر تاخير داشته باشد،
                  زمان جلسه افزايش می‌يابد و يا هزينه متناسب با زمان جلسه کسر می‌شود.
                </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <p class="font-weight-bold">کنسل کردن جلسه یا تغییر زمان:</p>
            <div>
              <ul class="pr-4">
                <li>
                  در صورت لزوم به تغییر و یا کنسلی در شرایط خاص لازم است حداقل پیش از 24 ساعت از زمان شروع جلسه اطلاع
                  داده
                  شود. کنسلی کمتر از 24 ساعت و یا عدم حضور در جلسه منجر به کسر هزینه کامل جلسه خواهد بود.
                  <br>
                  <b>
                    تغییر زمان بدون کسر هزینه (با اطلاع رسانی پیش از 24 ساعت) تنها دو بار ممکن است.
                  </b>
                </li>
                <li>
                  روان‌درمانگر نیز نسبت به مسیر درمانی شما متعهد است و چنانچه کنسلی کمتر از 24 ساعت رخ دهد جلسه جایگزین
                  بدون
                  دریافت هزینه برگزار خواهد شد.
                </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <p class="font-weight-bold">ارزیابی و سنجش کیفیت:</p>
            <div>
              مسیر ارزیابی کیفیت مسیر درمانی شما، شامل یک جلسه‌ی پیش مشاوره (Intake)، جلسه فالوآپ (Follow-up) و سیستم
              Simia Care می‌باشد.
              <ul class="pr-4">
                <li>
                  <b>
                    جلسات ارزیابی کیفیت:
                  </b>
                  جلسات ارزیابی کیفیت شما توسط
                  <b>
                    یکی از روانشناسان تیم Quality Care
                  </b>
                  انجام میپذیرد که
                  شامل پیش مشاوره در ابتدای مسیر و فالوآپ در میانه و انتهای مسیر است
                </li>
                <li>
                  <b>
                    سیستمSimia Care :
                  </b>
                  سیمیاروم، با مشارکت با یکی از پیشرفته ترین سیستم های بین المللی علمی سنجش، روشی را
                  برای سنجش و پیگیری پیشرفت درمانی شما ایجاد کرده‌ایم. این سیستم به درمانگر شما کمک می‌کند تا در هر جلسه
                  تراپی، بازخورد مستمر و ساختار یافته تری از وضعیت شما داشته باشد و با سنجش نتایج جلسات، مسیر درمانی شما
                  برای شما شخصی سازی شود.
                  پس هر بار که در جلسه تراپی شرکت می‌کنید، به یاد داشته باشید که استفاده از این سیستم بازخورد، به معنای
                  گرفتن نتایج بهتر و پیشرفت سریع‌تر است.
                </li>
              </ul>
            </div>
          </div>

          <div class="section">
            <p class="font-weight-bold">راه‌های ارتباطی:</p>
            <p>فارغ از پشتیبان و روانشناس تیم Quality Care که در تمامی مسیر گام به گام شما را همراهی خواهند کرد، راه‌های
              ارتباطی زیر در دسترس شما خواهند بود:</p>
            <ul class="pr-4">
              <li>مسئول ارتباط با مراجعان: <a href="mailto:Ali.R@simiaroom.com">Ali.R@simiaroom.com</a></li>
              <li>مسئول سنجش کیفیت: <a href="mailto:Samire@simiaroom.com">Samire@simiaroom.com</a></li>
              <li>مدیریت سیمیاروم: <a href="mailto:Mehdi@simiaroom.com">Mehdi@simiaroom.com</a></li>
            </ul>
          </div>

        </v-card-text>
        <v-card-actions class="justify-end pl-10 ">
          <div class="ml-1" @click="readIt = !readIt" style="cursor: pointer">مطالعه کردم و می‌پذیرم</div>
          <v-checkbox class="mt-4" dense :value="true" v-model="readIt">
          </v-checkbox>
          <v-btn class="mr-5" :color="readIt ? 'primary' : 'grey lighten-1'" elevation="0" @click="acceptConsent">
            تایید
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main class="pa-0 ma-0">
      <div class="fill-height">
        <div class="fill-height">
          <router-view></router-view>
        </div>
        <template v-if="snackbar.visibility">
          <v-fade-transition>
            <v-snackbar bottom :right="$vuetify.breakpoint.mdAndUp" v-model="snackbar.visibility"
                        :color="snackbar.color" class="text-center"
                        :timeout="snackbar.timeout">
              <div class="d-flex justify-space-between" :id="`${snackbar.id}_parent`">
                <div :id="snackbar.id">
                  {{ snackbar.title }}
                </div>
                <div @click="snackbar.visibility = false" style="cursor: pointer" v-ripple>
                  <v-icon>mdi-close</v-icon>
                </div>
              </div>
            </v-snackbar>
          </v-fade-transition>
        </template>
        <div :class="loader ? 'loaderBackArea' : ''" id="fill-it" :style="!loader && 'display:none'">
          <Loader :loader="loader"/>
        </div>
      </div>
    </v-main>
    <!--    <component :is="langeStyle[lang]"></component>-->
  </v-app>
</template>


<script>
// import EventBus from "../Lib/EventBus";

/*eslint-disable*/
import Fa from "@/components/Lang/fa";
import En from "@/components/Lang/en";
import Loader from "@/components/Main/Loader";
import EventsMixins from "@/mixins/EventsMixins";
import moment from "moment-jalaali";
import {mapGetters} from "vuex";

export default {
  name: 'App',
  components: {Loader},
  mixins: [EventsMixins],
  // components: {En, Fa},
  data: () => ({
    consent_modal: false,
    loader: false,
    readIt: false,
    snackbar: {
      visibility: false,
      color: 'green',
      title: 'test',
      timeout: 5000,
      id: null,
    },
    notRedirected: [
      'preorder',
      'testStepper',
      'onboarding',
      'match',
      'ResetPassword',
      'socialLogin',
      'login',
      'onboarding_new',
      "calender",
      "turkish_campaign",
      "interpretationTest",
      "LoginWithToken",
      'TelegramInitialize'
    ],
    langeStyle: {
      fa: 'Fa',
      en: 'En',
    },
  }),
  computed: {
    classForLoader() {
      return this.loader ? 'blurBackGround' : 'blurBackGroundNone'
    },
    lang() {
      return localStorage.getItem('local');
    },
    ...mapGetters({
      'isLoggedIn': "getIsLoggedIn",
      'consentVersion': "getConsentVersion",
      'profile': 'getProfile',
    })
  },
  watch: {
    '$route'() {
      window.scrollTo({top: 0})
      this.checkConsentForm(this.$store.getters.getProfile)
    },
    '$route.params.lang'() {
      this.bindLangClass()
    },
    // profile() {
    //   if (profile) {
    //   }
    // }
  },
  methods: {
    checkConsentForm(profile) {
      if (profile && this.isLoggedIn) {
        if (this.platFormInfoLS?.id  != 4 && !this.$route.path.includes('test')) {
          if (!profile.leadUser && (!profile.consent_at || profile.consent_version != this.consentVersion)) {
            this.consent_modal = true
          }
        }
      }
    },
    acceptConsent() {
      if (!this.readIt) {
        this.$emitEvent('notify', {title: 'لطفا تیک پذیرش شرایط را بزنید و سپس تایید نمایید', color: 'red'})
        return;
      }
      this.$emitEvent('setLoading', true)
      let data = {
        consent_version: this.consentVersion,
      }
      this.requesting('auth', 'setConsent', '', data).then(() => {
        this.requesting('auth', 'getProfile')
        this.$emitEvent('notify', {title: 'با موفقیت به ثبت رسید', color: 'green'})
        this.consent_modal = false
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    handleLoaderSize() {
      this.$nextTick().then(() => {
        let element = document.getElementById('fill-it')
        element.style.height = window.innerHeight + 'px';
        element.style.width = window.innerWidth + 'px';
      })
    },
    bindLangClass() {
      var body = document.querySelector('body');
      if (this.$route.params.lang) {
        switch (this.$route.params.lang) {
          case 'en':
            body.classList.add('en_body');
            break;
          case 'fa':
            body.classList.add('fa_body');
            break;
          default :
            body.classList.add('fa_body');
            break;
        }
      } else {
        body.classList.add('fa_body');
      }
      // Add the 'custom-font' class to the body
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleLoaderSize)
    this.handleLoaderSize()

    this.$onEvent('notify', ({title = "", color = 'black', id = null}) => {
      this.snackbar.visibility = false
      this.$nextTick().then(() => {
        this.snackbar = {
          visibility: true,
          color: color,
          title: title,
          id: id,
          timeout: 5000
        }
      })
    })
    this.$onEvent('setLoading', (val) => {
      this.$store.dispatch('setLoadingStatus', val)
      this.loader = val
      setTimeout(() => {
        this.loader = false
      }, 6000)
    })
  },
  created() {
    this.bindLangClass()
    this.requesting('auth', 'platformInfo')
    setTimeout(() => {
      this.requesting('auth', 'checklogin').catch((resp) => {
        if (resp.response.hasOwnProperty('status')) {
          if (resp.response.status == 401) {
            this.$store.dispatch('logout', false)
            if (!this.notRedirected.find(item => item == this.$route.name)) {
              this.$router.push({
                path: '/fa/login'
              })
            }
          } else {
            if (resp.response.data.data.hasOwnProperty('preflight')) {
              let payload = {
                key: 'preflight',
                data: resp.response.data.data.preflight
              }
              this.$store.commit('SET_PREFLIGHT', payload)
            }
          }
        }
      }).finally(() => {
        this.requesting('cart', 'getCart')
      })
    }, 2000)

    //
    // setTimeout(() => {
    //   try {
    //     console.log(ali)
    //     // Code that might throw an error
    //   } catch (error) {
    //     console.log(error )
    //     // The @sentry/vue integration will automatically capture this error
    //   }
    // }, 2000)


    if (this.$store.getters.getIsLoggedIn) {
      this.requesting('auth', 'getProfile').then((resp) => {
        let profile = resp.data.profile

        this.checkConsentForm(profile)
        this.WebengageSetUserRequiredFiled(profile)
        if (this.$route.meta.checkRequiredFiled) {
          // if ((profile.requiredFields.length != 0 || profile.cycles.length == 0) && !profile.leadUser) {
          //   if (this.$route.name != "onboarding_new") {
          //     this.$router.push({name: "onboarding_new"})
          //   }
          //   return;
          // }
          // if (profile.requiredFields.length != 0 && profile.leadUser) {
          //   if (this.$route.name != "userOnboarding") {
          //     this.$router.push({name: "userOnboarding"})
          //   }
          //   return;
          // }
        }
      });
    }
  },

};
</script>

<style>
h1, h2 {
  color: #333;
}

p {
  margin: 10px 0;
}

.section {
  margin-bottom: 20px;
}

.v-application a {
  color: inherit;
}

.advance .hooper-indicator {
  background: #ddd !important;
  padding: 3px 7px;
}

/*.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {*/
/*  */
/*}*/

.v-list-item__content .v-list-item__title {
  line-height: 1.5em !important;
}

.TodayNotation {
  width: 15px;
  height: 15px;
  background: #3CBE8B;
  border-radius: 50%;

}

.HasSessionNotation {
  width: 15px;
  height: 15px;
  background: #f6fff7 !important;
  border: 1px solid #3CBE8B;
  border-radius: 50%;
}

.HintLabel {
  font-size: 12px;
  margin-right: 5px;
}


.loaderBackArea {
  /*height: 100vh !important;*/
  /*overflow: hidden;*/
  position: fixed;
  /*overflow: scroll; */
  top: 0;
  left: 0;
  z-index: 99999;
  /*right: 0;*/
  /*width: 100%!important;*/
}


.blurBackGround {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  transition: all 0.1s ease;
}

.blurBackGroundNone {
  transition: all 0.3s ease;
}

.titleProfileSection {
  color: #4597DF;
  font-size: 18px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.en_arrow {
  transform: rotate(180deg);
  margin-left: 10px;
}

@media (min-width: 200px) and (max-width: 600px) {
  .genderIcon {
    width: 60px !important;
    height: 60px !important;
  }
}

@font-face {
  font-family: 'main';
  src: url("assets/fonts/Estedad-FD-v5.0/400-Estedad-FD-Regular.ttf");
}

@font-face {
  font-family: 'bold';
  src: url("assets/fonts/Estedad-FD-v5.0/600-Estedad-FD-SemiBold.ttf");
}

@font-face {
  font-family: 'semi-bold';
  src: url("assets/fonts/Estedad-FD-v5.0/500-Estedad-FD-Medium.ttf");
}

@font-face {
  font-family: 'en-num';
  src: url("assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: 'en-light';
  src: url("assets/fonts/Roboto-Light.ttf");
}

.en_light {
  font-family: en-light !important;
}

.en_body * {
  font-family: en-light !important;
  letter-spacing: 0px !important;
  scroll-behavior: smooth;
}

.fa_body * {
  /*direction: rtl !important;*/
  font-family: main !important;
  letter-spacing: 0px !important;
  scroll-behavior: smooth;
}

.en_num {
  font-family: en-num !important;
}


.phoneNumber input {
  direction: ltr !important;
}


/*@media (min-width: 200px) and (max-width: 600px) {*/
/*  body{*/
/*    width: auto !important;*/
/*    overflow: auto !important;*/
/*    overflow-x: hidden !important;*/
/*  }*/
/*}*/
body {
  /*width: 100%;*/
  /*overflow: hidden;*/
}

.fill-height {
  height: 100%;
}

.pmd-title-separator-center:after, .pmd-title-separator-right:after, .pmd-title-separator:after {
  content: "";
  display: block;
  width: 30px;
  height: 4px;
  border-radius: 3px;
  background-color: #f58634;
  margin: 0.65rem 0 0 1rem;
}


.v-application {
}

.ltrInput .v-text-field__slot input {
  direction: ltr !important;
}

.googleBtn {
  width: 268px !important;
  /*height: 50px !important;*/
  border-radius: 10px !important;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: right;
  color: #000 !important;
  background: transparent !important;
  border: 1px solid #46b1a1;
}

.customInput {
  border-bottom: 1px solid #6d6d6d;
  display: flex;
}

/*.customInput  .theme--light.v-text-field > .v-input__control > .v-input__slot:before{*/
/*  border-style: none !important;*/
/*}*/

.genderIcon {
  background-color: #b9b9b9;
  mask-size: cover !important;
  width: 75px;
  transition: all 0.3s;
  height: 75px;
  cursor: pointer;
}

.ActiveGenderText {
  color: #6d5aac;
  font-weight: bold;
  transition: all 0.3s;
}

.activeGender {
  background: #6d5aac;
  transition: all 0.3s;
}

.menGender {
  -webkit-mask: url('assets/userOnboarding/men.svg') no-repeat center;
  mask: url('assets/userOnboarding/men.svg') no-repeat center;
}

.womenGender {
  -webkit-mask: url('assets/userOnboarding/women.svg') no-repeat center;
  mask: url('assets/userOnboarding/women.svg') no-repeat center;
}

.otherGender {
  -webkit-mask: url('assets/userOnboarding/other.svg') no-repeat center;
  mask: url('assets/userOnboarding/other.svg') no-repeat center;
}

.genderArea p {
  font-size: 13px;
  color: #6d6d6d;
  transition: all 0.3s;
  text-align: center;
}

.centering {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.section * {
  line-height: 25pt;
}

</style>
