/*eslint-disable*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store'
import {i18n} from "@/plugins/vuetify";
import {EventBus} from "@/event-bus";

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (to.name == 'MainLayout') {
        next('/fa/panel/dashboard')
        return;
    }
    if (store.state.languages.findIndex(item => item == to.params.lang) != -1) {
        store.dispatch('setLocal', to.params.lang)
    } else {
        store.dispatch('setLocal', 'fa')
        // localStorage.setItem('local', 'fa')
    }

    if (to.meta.auth) {
        if (localStorage.getItem('isLoggedIn')) {
            // if (store.getters.getProfile && to.meta.checkRequiredFiled) {
            //     let profile = store.getters.getProfile
            //     // if ((profile.requiredFields.length != 0 || profile.cycles.length == 0) && !profile.leadUser) {
            //     //     next('/fa/onboarding-new')
            //     //     return;
            //     // }
            //     // if (profile.requiredFields.length != 0 && profile.leadUser) {
            //     //     next('/fa/panel/user-onboarding')
            //     //     return;
            //     // }
            // }
            next()
            return;
        } else {
            next('/fa/login')
            return
        }
    }
    if (to.meta.guest) {
        if (localStorage.getItem('isLoggedIn')) {
            next('/fa/panel/dashboard')
            return;
        }
    }

    next()
    return
})


// cURL error 35: OpenSSL SSL_connect: SSL_ERROR_SYSCALL in connection to sheets.googleapis.com:443 (see https://curl.haxx.se/libcurl/c/libcurl-errors.html) for https://sheets.googleapis.com/v4/spreadsheets/1UUdeo-vqXin9mCVR8N28ZUS1uyYp9ryi9igNQIrbhng/values/Register:append?valueInputOption=USER_ENTERED

export default router
