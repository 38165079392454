export default [
    {
        name: "getWalletUser",
        path: 'user/walletrecords?page=:page',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getSessionHistory",
        path: 'user/sessions/allexceptrequestsessions?page=:page',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "submitGiftCode",
        path: 'user/giftcodes',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },

]