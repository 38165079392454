import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueI18n from "vue-i18n";
import messages from "../../Lib/languages";

Vue.use(Vuetify);
Vue.use(VueI18n)


const currentURL = window.location.href;

// Extract the language from the URL
const languageRegex = /\/(\w{2})\//;
const match = currentURL.match(languageRegex);
let languageCode = null;

if (match) {
    languageCode = match[1];
    localStorage.setItem('local', languageCode);
}


/*eslint-disable*/
let rtl;
let localeLang = localStorage.getItem('local');
if (localeLang != null) {
    rtl = localeLang === 'fa';
} else {
    localeLang = 'fa';
    rtl = true;
}


console.log(localStorage.getItem('local'))
console.log({
    locale: localeLang, // set locale
    messages, // set locale messages
})
// console.log(rtl,'1212121')
export const i18n = new VueI18n({
    locale: localeLang, // set locale
    messages, // set locale messages
})
let config = {
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    rtl: rtl,
    theme: {
        themes: {
            light: {
                primary: '#3CBE8B',
            },
            dark: {},
        },
    },
}
console.log(config, '121212')
// console.log(config)
export default new Vuetify(config);
