import axios from "axios";
// import {EventBus} from "@/event-bus";
import {EventBus} from "@/event-bus";
import FileDownload from 'js-file-download';


const baseUrl = process.env.VUE_APP_BASE_URL

/*eslint-disable*/
export function ajaxGet(path, payload, header, notif) {
    if (path) {
        header.Authorization = 'Bearer ' + localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.get(baseUrl + path, {
                // withCredentials: true,
                headers: header
            }).then((resp) => {
                resolve(resp.data)
            }).catch((er) => {
                reject(er)
            })
        })
    }
}

export function ajaxPost(path, payload, header = {}, notif) {
    if (path) {
        header.Authorization = 'Bearer ' + localStorage.getItem('token')
        return new Promise((resolve, reject) => {
            axios.post(baseUrl + path, payload, {
                headers: header
            }).then((resp) => {
                resolve(resp.data)
            }).catch(er => {
                if (er.response.hasOwnProperty('data')) {
                    EventBus.$emitEvent('notify', ({title: er.response.data.message, color: 'red', id: 'notify'}))
                }
                reject(er)
            })
        })
    }
}

export function ajaxDownload(path, payload, header, notif) {
    if (path) {
        try {
            return new Promise((resolve, reject) => {
                axios.get(baseUrl + path, {
                    withCredentials: true,
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                    responseType: 'blob'
                }).then(resp => {
                    FileDownload(resp.data, 'file.' + payload.type);
                    resolve();
                }).catch((err) => {
                    // store.commit('setLoading', false);
                    EventBus.$emitEvent('notify', ({title: er.response.data.message, color: 'red', id: 'test '}))
                    reject(err);
                }).finally(() => {
                });
            });
        } catch (err) {
            // EventBus.$emit('notify', 'red', undefined, this.$t('$vuetify.Notify.error'));
            // store.commit('setLoading', false);
        }
    }
}

let httpUtilities = {
    get: ajaxGet,
    post: ajaxPost,
    download: ajaxDownload,
}
export default httpUtilities