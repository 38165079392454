export default [
    {
        name: "getTestInfo",
        path: 'psytest/test/:testID',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "submitAnswer",
        path: 'psytest/test/:testID/answer',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getInterpretation',
        path: 'psytest/test/:testID/userTest/:userTestID/result',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getInterpretationGuest',
        path: 'psytest/test/userTest/:userTestID/result',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getTestHistory',
        path: 'psytest/test/:testID/userTest',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getStatisticsTest',
        path: 'psytest/test/:testID/statistics',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'storeAiResponse',
        path: 'psytest/test/:testId/userTest/:userTestId/aiResponse',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'sendDataToAi',
        path: 'user/gpt-gateway/assistant/send-data',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getResponseAi',
        path: 'user/gpt-gateway/assistant/get-response',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]