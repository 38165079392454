export default [
    {
        name: "getCart",
        path: 'cart',
        method: "get",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_CART',
                key: 'cart',
                dataPath: 'data.[0]',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "addToCart",
        path: 'cart',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "removeFromCart",
        path: 'cart/remove',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "validateCoupon",
        path: 'validateCoupon',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "saveorder",
        path: 'saveorder',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "itemRemoveAll",
        path: 'cart/removeall',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]