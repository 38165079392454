/*eslint-disable-*/
export default {
    methods: {
        GTMCheckoutEvents(cart) {
            let products = this.prepareProductsForEvents(cart)
            this.$gtm.trackEvent({
                'event': 'eec.checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 1, 'option': 'Submit'},
                        products
                    }
                }
            });
            this.payBtnId = 'ga_payment_button';
        },
        WebengageSetUserLoggedIn(userID) {
            if (window.webengage && localStorage.getItem('webengage_login') != 'true') {
                window.webengage.user.login(userID);
                localStorage.setItem('webengage_login', true)
            }
        },
        WebengageSetUserRequiredFiled(profile) {
            this.WebengageSetUserLoggedIn(profile.id)
            if (window.webengage) {
                if (profile.email) {
                    window.webengage.user.setAttribute("we_email", profile.email);
                }
                if (profile.cel) {
                    window.webengage.user.setAttribute("we_phone", profile.cel.replaceAll(' ', ''));
                }
            }
        },
        WebengageUserRegister(userId, register_on, email, phone = null, fromTest = false) {
            if (window.webengage) {
                try {
                    if (register_on) {
                        window.webengage.user.setAttribute("registered_on", register_on);
                    }
                    if (email) {
                        window.webengage.user.setAttribute("we_email", email);
                    }
                    if (phone) {
                        window.webengage.user.setAttribute("we_phone", phone.replaceAll(' ', ''));
                    }
                    if (fromTest) {
                        window.webengage.track('registered_on', {
                            date: register_on,
                            type: 'psyregister'
                        });
                    }else{
                        window.webengage.track('registered_on', {
                            date: register_on,
                        });
                    }
                    this.WebengageSetUserLoggedIn(userId)
                    console.log('sent register data to web engage')
                } catch (error) {
                    console.log('have problem in send data to webengage' + error)
                }
            }
            // console.log('ok')
        },
        WebengageProceedToPayment() {
            this.sendToWebengage('proceed_to_payment')
        },
        CouponCodeWebengageTrack(code, status) {
            let payload = {
                code: code
            }
            if (status == 'success') {
                this.sendToWebengage('promotion_code_success', payload)
            } else if (status == 'failed') {
                this.sendToWebengage('promotion_code_failure', payload)
            }
        },
        sendToWebengage(event, data) {
            if (window.webengage) {
                window.webengage.track(event, data);
            }
        },
        sendAddToCartEvents(resp) {
            let addedPackage = resp.data.package;
            let product = {
                'name': addedPackage.title,
                'id': addedPackage.id,
                'price': addedPackage.pivot.price,
                'brand': 'Simiaroom',
                'category': 'Counseling',
                'quantity': 1
            };

            if (window.webengage) {
                window.webengage.track('add_to_cart', product);
            }

            this.$gtm.trackEvent({
                'event': 'eec.addToCart',
                'ecommerce': {
                    'add': {
                        'products': [product]
                    }
                }
            });
        },
        prepareProductsForEvents(cart) {
            let products = [];
            Object.keys(cart.items).forEach((key) => {
                let item = cart.items[key];
                products.push({
                    'name': item.item.title,
                    'id': item.item.id,
                    'price': item.price,
                    'brand': 'Simiaroom',
                    'category': 'Counseling',
                    // 'variant': 'Gray',
                    'quantity': item.qty
                })
            })
            return products
        },
        GTMAddToCart(item) {
            this.$gtm.trackEvent({
                'event': 'eec.addToCart',
                'ecommerce': {
                    'add': {
                        'products': [{
                            'name': item.item.title,
                            'id': item.item.id,
                            'price': item.price,
                            'brand': 'Simiaroom',
                            'category': 'Counseling',
                            // 'variant': 'Gray',
                            'quantity': 1
                        }]
                    }
                }
            });
        },
        WebengageAddToCart(item) {
            let product = {
                'name': item.item.title,
                'id': item.item.id,
                'price': item.price,
                'brand': 'Simiaroom',
                'category': 'Counseling',
                'quantity': 1
            };

            this.sendToWebengage('add_to_cart', product)

        },
        GTMRemoveFromCart(item) {
            this.$gtm.trackEvent({
                'event': 'eec.removeFromCart',
                'ecommerce': {
                    'remove': {                               // 'remove' actionFieldObject measures.
                        'products': [{
                            'name': item.item.title,
                            'id': item.item.id,
                            'price': item.price,
                            'brand': 'Simiaroom',
                            'category': 'Counseling',
                            // 'variant': 'Gray',
                            'quantity': 1
                        }]
                    }
                }
            });
        }
    }
}