export default [
    {
        name: "initTest",
        path: 'user/matchmaking/test/init/1?check=1',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "initTestWithOutCheck",
        path: 'user/matchmaking/test/init/1',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "initTestWithPsychology",
        path: 'user/matchmaking/test/init/1?psychology_field_id=:psychology_field_id',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'submitAnswer',
        path: 'user/matchmaking/test/answerquestion',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getTestResult',
        path: 'user/matchmaking/test/result',
        method: "post",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: 'getPsychologyFields',
        path: 'user/psychologyfields/list',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]