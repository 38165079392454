export default [
    {
        name: "getContacts",
        path: 'user/contacts',
        method: "get",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_CONTACTS',
                key: 'contacts',
                dataPath: 'data.contacts',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "getMessageByUser",
        path: 'user/conversations/:userId/getbyuser?perPage=20&page=:page',
        method: "get",
        loading: true,
        alert: true,
        commits: [
            {
                name: 'SET_MESSAGES',
                key: 'messages',
                dataPath: 'data.messages',
                localStoragePersist: true,
                debug: false,
                before: () => {
                },
                after: () => {
                },
            },
        ],
        defaultHeader: {},
        defaultBody: {}
    },
    {
        name: "sendMessage",
        path: 'user/conversations/:conversionId/messages',
        method: "post",
        loading: true,
        alert: true,
        defaultHeader: {},
        defaultBody: {}
    },
]