import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import globalMixins from "@/mixins/globalMixins";
import EventBus from "../Lib/EventBus";
import VueI18n from 'vue-i18n'
import {i18n} from "./plugins/vuetify";
import Socket from "../Lib/socket";
import VueGtm from 'vue-gtm';
import VueSocialSharing from "vue-social-sharing";
import Meta from 'vue-meta';
import * as Sentry from "@sentry/vue";



Vue.use(VueSocialSharing);
Vue.config.productionTip = false
Vue.use(Meta);

Vue.use(VueGtm, {
    // id:'GTM-WBKRP9P',
    enabled: true,
    loadScript: true
});

Vue.mixin(globalMixins)
Vue.use(EventBus)
new Socket().initialSocket()

Vue.use(VueI18n)

Sentry.init({
    Vue,
    dsn: "https://8b001bcbd2739ffd7be2c0a7431b9eda@o1261388.ingest.sentry.io/4505690877001728",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app')
