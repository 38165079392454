import store from "@/store";
import {io} from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import Vue from "vue";

/*eslint-disable*/
class Socket {
    constructor() {

    }

    initialSocket() {
        this.socket = io('https://socket.simiaroom.com', {
            query: `token=${store.getters.getBcCode}`
        });
        Vue.use(VueSocketIOExt, this.socket);
        this.socket.on('connect', () => {
            console.log('connected')
        })
    }

    bindEvenListener() {

    }
}

export default Socket;