export default [
    {
        name: "getPaymentInfo",
        path: 'user/validatePurchase/:paymentID',
        method: "get",
        loading: true,
        alert: true,
        commits: [],
        defaultHeader: {},
        defaultBody: {}
    },
]